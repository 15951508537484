import React, { useEffect, useRef, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid"
import SwiperCore, { Navigation, Thumbs } from "swiper/core"
import { useTranslation } from "react-i18next"

// install Swiper modules
SwiperCore.use([Navigation, Thumbs])

const SingleProductSwiper = ({
  images,
  setZoomImage,
  setZoom,
  zoom,
  noZoom,
  // onlyOneImage,
}) => {
  // Shineer nemsen
  const ref = useRef(null)
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language

  const onlyOneImage = images?.length === 1
  // const imageIsNotArray = images.isArray
  // console.log(onlyOneImage)

  const goNext = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slidePrev()
    }
  }

  const [activeSliderIndex, setActiveSliderIndex] = useState(0)
  // console.log(activeSliderIndex)
  // const [zoomImage, setZoomImage] = useState(null)
  useEffect(() => {
    // console.log(activeSliderIndex)
    // setZoomImage(allSlideImage[activeSliderIndex])
    setZoomImage(images[activeSliderIndex])
  }, [activeSliderIndex])

  // const allSlideImage = [image].concat(tuslahImages)

  const slides = images.map((item, index) => {
    return (
      <SwiperSlide
        key={index}
        tag="li"
        className={`w-full overflow-hidden rounded  ${
          zoom && !noZoom ? "md:opacity-50" : ""
        }`}
        style={{
          WebkitBackfaceVisibility: "hidden",
          MozBackfaceVisibility: "hidden",
          WebkitTransform: "translate3d(0, 0, 0)",
          MozTransform: "translate3d(0, 0, 0)",
        }}
      >
        <div
          className="flex object-cover w-full overflow-hidden select-none h-96"
          onMouseEnter={() => setZoom(true)}
          onMouseLeave={() => setZoom(false)}
        >
          {item}
          <div className="absolute inset-0 w-full h-full">&nbsp;</div>
        </div>
      </SwiperSlide>
    )
  })

  const thumbs = images.map((item, index) => {
    return (
      <SwiperSlide
        key={index}
        tag="li"
        className="w-full overflow-hidden select-none"
      >
        <div className="flex object-cover h-16 overflow-hidden">
          {item}
          <div className="absolute inset-0 w-full h-full">&nbsp;</div>
        </div>
      </SwiperSlide>
    )
  })

  // const [zoom, setZoom] = useState(false)
  const lastSlideIndex = slides.length - 1
  const [firstSlide, setFirstSlide] = useState(true)
  const [lastSlide, setLastSlide] = useState(false)
  const inactiveSlideCSS = "text-gray-100 pointer-events-none"

  const swiper = (
    <div className="space-y-2 text-center">
      <div>
        <Swiper
          id="main"
          tag="section"
          wrapperTag="ul"
          thumbs={{ swiper: thumbsSwiper }}
          navigation
          ref={ref}
          spaceBetween={7}
          slidesPerView={1}
          // onInit={swiper => (
          //   console.log("Swiper initialized", swiper),
          //   console.log("Swiper activeIndex", swiper.activeIndex),
          //   setFirstSlide(true),
          //   swiper.activeIndex === 0
          //     ? setFirstSlide(true)
          //     : setFirstSlide(false),
          //   swiper.activeIndex === lastSlideIndex
          //     ? setLastSlide(true)
          //     : setLastSlide(false)
          // )}
          onSlideChange={swiper => (
            // console.log("Slide index changed to: ", swiper.activeIndex),
            // setFirstSlide(false),
            setActiveSliderIndex(swiper.activeIndex),
            swiper.activeIndex === 0
              ? setFirstSlide(true)
              : setFirstSlide(false),
            swiper.activeIndex === lastSlideIndex
              ? setLastSlide(true)
              : setLastSlide(false)
          )}
          // onReachEnd={() => console.log("Swiper end reached")}
          onReachEnd={() => setLastSlide(true)}
          onReachBeginning={() => setFirstSlide(true)}
        >
          {slides}
        </Swiper>
      </div>

      <div className={`${onlyOneImage ? "hidden" : ""}`}>
        {!noZoom && (
          <Swiper
            id="thumbs"
            spaceBetween={5}
            onSwiper={setThumbsSwiper}
            watchSlidesVisibility
            watchSlidesProgress
            slidesPerView={4}
            className="mt-2 "
          >
            {thumbs}
          </Swiper>
        )}

        <div
          className="inline-flex items-center justify-center mt-2"
          onMouseEnter={() => setZoom(true)}
          onMouseLeave={() => setZoom(false)}
        >
          <button
            onClick={goPrev}
            aria-disabled="true"
            className={`outline-none focus:outline-none ${
              firstSlide ? inactiveSlideCSS : ""
            }`}
          >
            <ChevronLeftIcon
              aria-disabled="true"
              className="w-12 h-12 p-2 rounded-full outline-none hover:text-gray-900 hover:bg-gray-100 focus:outline-none"
            />
          </button>
          <button
            onClick={goNext}
            aria-disabled="true"
            className={` outline-none focus:outline-none ${
              lastSlide ? inactiveSlideCSS : ""
            }`}
          >
            <ChevronRightIcon
              aria-disabled="true"
              className="w-12 h-12 p-2 rounded-full outline-none hover:text-gray-900 hover:bg-gray-100 focus:outline-none"
            />
          </button>
        </div>
      </div>
    </div>
  )

  return swiper
}

export default SingleProductSwiper
