import React, { useEffect, useState } from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { AllWorks } from "../graphql/WorksSmallQueries"
import PageHeader from "../templates/PageHeader"
import SingleProductSwiper from "../components/SingleProductSwiper"
import {
  ProjectNameText,
  WorkText,
  WorkTitle,
  OgnooText,
  ZahialagchText,
  ProductText,
} from "../graphql/QueryById"

const Works = () => {
  const works = AllWorks()
  // console.log(works)
  const [zoom, setZoom] = useState(false)
  const [zoomImage, setZoomImage] = useState(null)

  const allworks = works.map((item, index) => {
    const { company, companyLogo, date, name, projectLogo, supply, images } =
      item ?? undefined
    // console.log(supply)

    return (
      <div
        key={index}
        className="grid justify-center grid-cols-1 pt-10 md:grid-cols-2 md:px-0"
      >
        {/* <div className="mx-auto w-80 sm:w-120 "> */}
        <div className="">
          <SingleProductSwiper
            images={images}
            setZoomImage={setZoomImage}
            setZoom={setZoom}
            zoom={zoom}
            noZoom
          />
        </div>
        <div className="flex flex-col max-w-md mx-2 mt-10 divide-y md:mx-4 md:max-w-none">
          <div className="relative grid items-center grid-cols-3 space-x-2 group">
            <div className="flex items-center h-full col-span-1 px-2 py-2 bg-gray-100">
              {ProjectNameText()}
            </div>
            <div className="relative flex items-center col-span-2 py-2 lg:col-span-2">
              {name}
            </div>
          </div>
          <div className="relative grid items-center grid-cols-3 space-x-2 group">
            <div className="flex items-center h-full col-span-1 px-2 py-2 bg-gray-100">
              {ZahialagchText()}
            </div>
            <div className="relative flex items-center col-span-2 py-2 lg:col-span-2">
              {company}
              <div className="absolute right-0 z-10 items-center justify-center hidden w-48 p-2 overflow-hidden bg-white rounded shadow-lg group-hover:flex">
                {companyLogo}
              </div>
            </div>
          </div>
          <div className="relative grid items-center grid-cols-3 space-x-2 group">
            <div className="flex items-center h-full col-span-1 px-2 py-2 bg-gray-100">
              {OgnooText()}
            </div>
            <div className="relative flex items-center col-span-2 py-2 lg:col-span-2">
              {date}
            </div>
          </div>
          <div className="relative grid items-center grid-cols-3 space-x-2 ">
            <div className="flex items-center h-full col-span-1 px-2 py-2 bg-gray-100">
              {ProductText()}
            </div>
            <div className="relative flex items-center col-span-2 py-2 space-x-2 lg:col-span-2">
              {supply.map((item, index) => {
                return (
                  <div key={index} className="inline-flex group">
                    <div className="inline-flex w-8 h-full transform group-hover:scale-125">
                      {item.icon}
                    </div>
                    <div className="absolute inset-x-0 z-20 justify-center hidden w-full px-4 py-2 leading-tight text-center text-white bg-gray-500 border rounded-lg shadow-lg -top-9 group-hover:flex bg-opacity-95">
                      {item.name}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  })

  return (
    <Layout>
      <SEO title={WorkTitle()} description='Nippon standard-ийн гүйцэтгэсэн төсөл хөтөлбөрүүд' />
      {/* <PageHeader title={WorkText()} /> */}
      <div className="flex justify-center mx-10 mt-10 text-xl font-semibold text-center text-brand md:my-20 md:text-3xl">
        {/* Гүйцэтгэсэн төслүүд */}
        {WorkTitle()}
      </div>
      {/* <div className="relative z-20 grid max-w-screen-lg grid-cols-1 gap-10 p-10 mx-auto my-10 bg-white rounded shadow-xl">
        {allworks}
      </div> */}
      <div className="relative z-20 max-w-screen-lg mx-auto mb-10 space-y-10 divide-y md:shadow-xl md:p-10 md:bg-white">
        {allworks}
      </div>
    </Layout>
  )
}

export default Works
